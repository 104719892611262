<template>
  <div id="app">
    <tabbar @setFIndex="setFIndex" :activeid="nowIndex"/>
    <!-- <banner/> -->
    <router-view/>
    <footers @setFIndex="setFIndex"/>
  </div>
</template>
<script>
// @ is an alias to /src
import tabbar from '@/components/tabbar.vue'
import banner from '@/components/banner.vue'
import footers from '@/components/footer.vue'

export default {
  name: 'app',
  components: {
    tabbar, footers, banner
  },
  // watch:{
  //   nowIndex:{
  //     immediate: true,
  //     handler(val) {
  //       console.log('app watch',val)
  //     }
  //   }
  // },
  data(){
    return {
      nowIndex: 0
    }
  },
  methods: {
    setFIndex(idx){
      this.nowIndex = idx;
    }
  }
}
</script>
<style lang="scss">
html, body, #app{
  margin: 0;
  padding: 0;
  height: 100%;
  position: relative;
  background: #F8F9FA; // #7bbfea;// 
}
#app{
  width: 100%; //1920px;
  margin: 0 auto;
  overflow: hidden;
  overflow-y: scroll;
  // &::-webkit-scrollbar{
  // }
}
p, ul, li{
  margin: 0;
  padding: 0;
}
.page{
  width: 1920px;
  margin: auto;
  display: table;
}
.cur-point{
  cursor: pointer;
}
</style>
