<template>
    <div class="banner">
        <p class="p1">小包子Maas<br/>元宇宙综合解决方案</p>
        <p class="p2">用技术赋能城市所有场景空间，助力产业升级</p>
         <!-- @mouseleave="show = false" -->
        <span @click="ask" @mouseover="show = true" @mouseleave="show = false">立即咨询</span>
        <div v-if="show" class="hov">
            <img class="sol" src="../assets/images/Polygon.png" alt="" srcset="">
            <img class="qr" src="../assets/images/eewmm.svg" alt="" srcset="">
        </div>
    </div>
</template>
<script>
export default {
    name: 'banner',
    data(){
        return {
            show: false,
        }
    },
    methods: {
        ask(){
        }
    }
}
</script>
<style lang="scss" scoped>
.banner{
    background: url('../assets/images/banner.png');
    background-size: 1920px 824px;
    background-repeat: no-repeat;
    max-width: 1920px;
    height: 824px;
    padding-left: 182px;
    position: relative;
    .p1{
        font-size: 58px;
        font-family: PingFang SC-Semibold, PingFang SC;
        font-weight: 600;
        padding-top: 194px;
    }
    .p2{
        font-size: 20px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        padding-top: 30px;
        color: #868E96;
    }
    span{
        margin-top: 30px;
        width: 157px;
        display: inline-block;
        text-align: center;
        line-height: 56px;
        background: #F76000;
        border-radius: 64px;
        font-size: 20px;
        color: #fff;
        font-family: PingFang SC-Bold, PingFang SC;
    }
    .hov{
        position: absolute;
        top: 500px;
        left: 182px;
        width: 200px;
        height: 200px;
        background: #fff;
        box-shadow: 0px 6px 20px 1px rgb(0 0 0 / 16%);
        border-radius: 6px;
        .sol{
            position: absolute;
            top: -8px;
            left: 30px;
        }
        .qr{
            width: 180px;
            height: 180px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px;
        }
    }
}
</style>