import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/Pages/index.vue')
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/Pages/case.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/Pages/about.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/Pages/news.vue')
  },
  {
    path: '/product/lines',
    name: 'clian',
    component: () => import('../views/Product/clian.vue')
  },
  {
    path: '/product/flow',
    name: 'cliu',
    component: () => import('../views/Product/cliu.vue')
  },
  {
    path: '/product/mecard',
    name: 'mecard',
    component: () => import('../views/Product/mecard.vue')
  },
  {
    path: '/product/greens',
    name: 'lz',
    component: () => import('../views/Product/lz.vue')
  },
  {
    path: '/product/greens',
    name: 'lz',
    component: () => import('../views/Product/lz.vue')
  },
  {
    path: '/product/pingcyun',
    name: 'pcy',
    component: () => import('../views/Product/pingcyun.vue')
  },
  {
    path: '/scheme/nft',
    name: 'nft',
    component: () => import('../views/Scheme/nft.vue')
  },
  {
    path: '/scheme/virtual',
    name: 'xnr',
    component: () => import('../views/Scheme/xnr.vue')
  },
  {
    path: '/scheme/uni',
    name: 'yyz',
    component: () => import('../views/Scheme/yyz.vue')
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
//所有的路由请求都会经过该路由守卫，
router.beforeEach((to, from, next) => {
  // if (typeof window !== 'undefined' && typeof window.navigator !== 'undefined') {
  //   if(/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
  //     // 移动端
  //     if(process.env.NODE_ENV=='production-test' || process.env.NODE_ENV=='production'){
  //       window.location.href = process.env.VUE_APP_REDIRECT_URL
  //     }
  //   }else{
  //     //处理电脑端的业务逻辑
  //     // if(process.env.NODE_ENV=='production-test' || process.env.NODE_ENV=='production'){
  //     //   window.location.href = process.env.REDIRECT_PC_URL
  //     // }
  //   }
  // }                                                          
  //该路由请求放行
  next();
});

export default router
