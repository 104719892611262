<template>
    <div class="tabbar">
        <div class="wrap">
            <img src="../assets/images/logo.png" alt="" srcset="">
            <div class="items">
                <div :class="['item', item.on_active ? 'on-active' : '', past_idx === index ? 'past-act' : '']" v-for="(item, index) in list" :key="index" 
                    @mouseenter="showPop(item, index)" @click="goto(item, index)" @mouseleave="past_idx = -1, show = false,child_past_idx = -1" 
                 >
                    {{item.name}}
                    <transition name="fade">
                        <ul v-if="item.child && nowclick === index && show">
                            <img src="../assets/images/Polygon.png" alt="" srcset="">
                            <li :class="['childs', idx === 0 ? 'fir':'', it.disable ? 'disable' : '', child_past_idx === idx ? 'child_past' : '']" 
                                 @mouseenter="setChildCss(it, idx)" v-for="(it, idx) in item.child" :key="idx" @click.stop="childgo(it, idx, index)">{{it.name}}</li>
                        </ul>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'tabbar',
    props: {
        activeid: {
            type: Number,
            default: 1
        }
    },
    watch:{
        activeid:{
            immediate: true,
            handler(val,oval) {
                if(!oval){
                    this.list[0].on_active = false;
                }
                if(!!oval){
                    this.list[oval].on_active = false;
                }
                this.list[val].on_active = true;
            }
        }
    },
    data(){
        return {
            list: [{
                name: '首页',
                id: 'index',
                path: '/',
                on_active: true,
            },{
                name: '产品',
                id: 'product',
                path: '/product',
                on_active: false,
                child: [{
                    name: 'Mecard',
                    id: 'mecard',
                    path: '/product/mecard',
                    on_active: false,
                    disable: true 
                },{
                    name: '绿洲',
                    id: 'lvzhou',
                    path: '/product/greens',
                    on_active: false,
                    disable: true 
                },{
                    name: '川留',
                    id: 'cliu',
                    path: '/product/flow',
                    on_active: false,
                },{
                    name: '川链',
                    id: 'clian',
                    path: '/product/lines',
                    on_active: false,
                },{
                    name: '平川云平台',
                    id: 'pingchuan',
                    path: '/product/pingcyun',
                    on_active: false,
                },]
            },{
                name: '方案',
                id: 'scheme',
                path: '/scheme ',
                on_active: false,
                child: [{
                    name: '产业元宇宙',
                    id: 'yyz',
                    path: '/scheme/uni',
                    on_active: false,
                },{
                    name: '虚拟人',
                    id: 'xnr',
                    path: '/scheme/virtual',
                    on_active: false,
                },{
                    name: 'NFT',
                    id: 'nft',
                    path: '/scheme/nft',
                    on_active: false,
                },]
            },{
                name: '案例',
                id: 'case',
                path: '/case',
                on_active: false,
            },{
                name: '关于小包子',
                id: 'about',
                path: '/about',
                on_active: false,
            },{
                name: '新闻中心',
                id: 'news',
                path: '/news',
                on_active: false,
            },],
            // activeid: 0,
            show: false,
            nowclick: -1,
            past_idx: -1,
            child_past_idx: -1,
        }
    },
    methods:{
        setChildCss(item, idx){
            if(!item.disable)
                this.child_past_idx = idx
        },
        showPop(item, index){
            this.past_idx = index;
            if(item.child){
                this.nowclick = index;
                this.show = true;
            }else{
                this.show = false;
            }
        },
        goto(item, index){
            // this.list[this.activeid].on_active = false;
            if(!item.child){
                this.list[this.activeid].on_active = false;
                this.$router.push(item.path).catch(err =>{})
                this.$emit('setFIndex', index)
                this.list[index].on_active = true;
            }
            this.nowclick = index;
            // this.list[index].on_active = true;
            // this.show = true;
            // this.activeid = index;
        },
        childgo(it, idx, index){
            if(it.disable) return;
            this.list[this.activeid].on_active = false;
            this.show = false;
            this.nowclick = -1
            this.$emit('setFIndex', index)
            this.$router.push(it.path).catch(err =>{})
            this.list[index].on_active = true;
        }
    }
}
</script>
<style lang="scss" scoped>
.tabbar{
    position: relative;
    width: 1920px;
    background: rgba(45, 45, 45, 0.88);
    height: 88px;
    display: flex;
    align-items: center;
    margin: auto;
    .wrap{
        width: 1556px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
        height: 88px;
        img{
            width: 167.76px;
            height: 56.29px;
        }
        .items{
            position: relative;
            font-size: 18px;
            color: #fff;
            display: inline-flex;
            height: 88px;
            .item{
                padding: 0 16px;
                position: relative;
                height: 88px;
                display: flex;
                align-items: center;
                z-index: 999;
                cursor: pointer;
                ul{
                    position: absolute;
                    display: block;
                    width: 204px;
                    color: #333;
                    font-size: 18px;
                    text-align: center;
                    top: 68px;
                    left: 0;
                    transform: translateX(-68px);
                    z-index: 999;
                    // box-shadow: 0px 3px 7px 1px rgb(0 0 0 / 30%);
                    // transition: 300ms;
                    img{
                        width: 14px;
                        height: 10px;
                    }
                    li{
                        display: block;
                    }
                    .arrow{
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        margin: auto;
                        width: 10px;
                    }
                    .childs{
                        line-height: 49px;
                        background: #fff;
                    }
                    .fir{
                        margin-top: -5px;
                    }
                }
            }
            .on-active{
                color: #F76000;
            }
            .disable{
                color: #ccc;
            }
        }
    }
    .past-act{
        // font-size: 20px;
        // font-weight: 550;
        color: #F76000;
    }
    .child_past{
        background: #F76000!important;
        color: #fff;
    }
    .past-act::before{
        content: ' ';
        position: absolute;
        width: 100%;
        height: 6px;
        background: #F76000;
        top: 0;
        left: 0;
    }
    .fade-enter{
        opacity:0;
    }
    .fade-enter-active{
        transition:opacity .5s;
    }
    .fade-leave-active{
        transition:transform .1s;
    }
    .fade-leave-to{
        transform:translateX(10px);
    }
}
</style>