<template>
  <div class="footer">
    <div class="wrap">
        <div class="lt">
            <img src="../assets/images/eewmm.svg" alt="" srcset="">
            <div>
                <p>广州小包子数据科技有限公司</p>
                <p>全国热线：400-889-1113</p>
                <p>地址：广州市黄埔区科学城科汇四街5号301-1房</p>
            </div>
        </div>
        <div class="rt">
            <div>
                <span v-for="(item, index) in list" :key="index" @click="goto(item.path, index)">{{item.name}}</span>
            </div>
            <p>COPYRIGHT©2022—2027 XBZGJ.COM.INC.ALL RIGHTS RESERVED. <i class="ban" @click="toban">粤ICP备2021009065号</i></p>
        </div>
    </div>
  </div>
</template>
<script>
export default {
    name: 'footers',
    props: {
        activeid: {
            type: Number,
            default: 0
        }
    },
    data(){
        return {
            list: [{
                name: '首页',
                id: 'index',
                path: '/',
                on_active: true,
            },{
                name: '产品',
                id: 'product',
                path: '/product/flow',
                on_active: false,
            },{
                name: '方案',
                id: 'scheme',
                path: '/scheme/uni',
                on_active: false,
            },{
                name: '案例',
                id: 'case',
                path: '/case',
                on_active: false,
            },{
                name: '关于小包子',
                id: 'about',
                path: '/about',
                on_active: false,
            },{
                name: '新闻中心',
                id: 'news',
                path: '/news',
                on_active: false,
            },],
        }
    },
    methods:{
        goto(path, index){
            this.$router.push(path).catch(err => {})
            this.$emit('setFIndex', index)
        },
        toban(){
            window.open('https://beian.miit.gov.cn','_blank')
        }
    }
}
</script>
<style lang="scss" scoped>
.footer{
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    background: rgba(45, 45, 45, 0.88);
    width: 1920px;
    margin: auto;
    .wrap{
        width: 1540px;
        display: flex;
        justify-content: space-between;
        padding: 39px 0 38px;
        margin: auto;
        .lt{
            display: flex;
            align-items: center;
            img{
                width: 98px;
                height: 98px;
                margin-right: 24px
            }
            div{
                p{
                    &:nth-child(2){
                        padding: 10px 0 7px;
                    }
                }
            }
        }
        .rt{
            div{
                display: flex;
                justify-content: space-between;
                span{
                    padding: 0 26px;
                    border-right: 1px solid #fff;
                    &:last-child{
                        border-right: 0 solid #fff;
                    }
                }
            }
            p{
                padding-top: 38px;
                font-size: 14px;
                text-align: center;
            }
        }
    }
    .ban{
        font-style: normal;
    }
}
</style>